<template>
  <div class="home">
    <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
      <DataCard
        :data="filteredLinks"
        :fields="fields"
        fieldEventName="name"
        @cardClick="cardClick"
      />
    </q-pull-to-refresh>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DataCard from "@/components/DataCard";

export default {
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "icon",
          label: "",
          type: "icon"
        },
        {
          field: "length",
          label: "",
          type: "badgeFloating"
        },
        {
          field: "caption",
          label: "",
          type: "string",
          class: "text-bold"
        }
      ]
    };
  },
  computed: {
    ...mapState("users", ["loggedInUser"]),
    ...mapState("contingencies", ["contingencies"]),
    ...mapState("payments", ["payments"]),
    ...mapState("purchaseOrders", ["orders"]),
    ...mapState("historyOrders", ["history"]),
    ...mapState("purchaseRequests", ["requests"]),
    ...mapState("warehouseRequests", ["warehouse"]),
    ...mapState("tradeAgreement", ["agreement"]),
    ...mapState("financialtitle", ["financialtitle"]),
    ...mapGetters("shared", ["appLinks"]),
    filteredLinks() {
      let result = [];
      if (this.loggedInUser.isAdministrator) {
        result = this.appLinks.filter(link => link.showHome);
      } else {
        result = this.appLinks.filter(
          link => link.showHome && !link.onlyAdministrator
        );
      }

      result.map(item => {
        item.length = this[item.name] ? this[item.name].length : 0;
        return item;
      });

      return result;
    }
  },
  methods: {
    ...mapActions("contingencies", ["getContingencies"]),
    ...mapActions("purchaseOrders", ["getOrders"]),
    ...mapActions("payments", ["getPayments"]),
    ...mapActions("purchaseRequests", ["getRequests"]),
    ...mapActions("warehouseRequests", ["getWarehouseRequests"]),
    ...mapActions("tradeAgreement", ["getAgreement"]),
    ...mapActions("financialtitle", ["getFinancialTitle"]),
    cardClick(name) {
      this.$router.push({ name });
    },
    async refresh(done) {
      await this.getFinancialTitle();
      await this.getContingencies();
      await this.getOrders();
      await this.getRequests();
      await this.getPayments();
      await this.getWarehouseRequests();
      await this.getAgreement();

      done();
    }
  },
  async mounted() {
    await this.getFinancialTitle();
    await this.getContingencies();
    await this.getOrders();
    await this.getRequests();
    await this.getPayments();
    await this.getWarehouseRequests();
    await this.getAgreement();
  }
};
</script>

<style lang="scss">
.pullRefresh {
  height: 100%;
  width: 100%;
}
</style>
